<template>
  <header>
    <header-nav :user="user_rows"></header-nav>
  </header>
  <aside>
    <aside-menu :uid="user_rows.id"></aside-menu>
  </aside>
  <main ref="main">
    <router-view :user="user_rows" :warehouses="warehouses" v-slot="{ Component }" @changeUser="changeUser" @addEzway="displayEzway">
      <transition :name="$route.meta.transition" @transitionstart="start()" @transitionend="end()">
      <component :is="Component" />
      </transition>
    </router-view>
  </main>
  <footer>
    <member-footer></member-footer>
  </footer>
  <user-detail v-bind="user_dialog" @toFill="toFill"></user-detail>
  <ezway-dialog v-bind="ez_dialog" @toInput="closeEzway"></ezway-dialog>
  <!-- built files will be auto injected -->
  <!-- Messenger 洽談外掛程式 Code -->
  <div id="fb-root"></div>
  <!-- Your 洽談外掛程式 code -->
  <div id="fb-customer-chat" class="fb-customerchat">
  </div>

</template>

<script>
import axios from 'axios';
// @ is an alias to /src
import HeaderNav from '@/components/HeaderNav.vue';
import AsideMenu from '@/components/AsideMenu.vue';
import MemberFooter from '@/components/MemberFooter.vue';
import UserDetail from '@/components/UserDetail.vue';
import EzwayDialog from '@/components/EzwayDialog.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderNav,
    AsideMenu,
    MemberFooter,
    UserDetail,
    EzwayDialog,
  },
  emits: ['updateStatus'],
  props: ['user_rows'],
  data() {
    return {
      warehouses: [],
      user_dialog: {
        isShow: false,
        title: '補充會員資料',
        name: '',
        user: {}
      },
      ez_dialog: {
        isShow: false,
        force: false 
      },
    };
  },
  watch: {
  },
  mounted() {
    this.checkUserRows();
    this.message();
    this.getWarehouse();
    //this.watchMsg();
  },
  methods: {
    toFill(new_info) {
      this.$emit('updateStatus', new_info);
      this.user_dialog.isShow = false;
    },
    displayEzway(val) {
      if (val === 1) {
        this.ez_dialog.isShow = true;
      }
    },
    closeEzway(val) {
      if (val === '0') {
        this.ez_dialog.isShow = false;
      }
      if (val && val.dr_books) {
        this.$emit('updateStatus', val);
      }
    },
    getWarehouse() {
      let config = {
        method: 'get',
        url: `/api/warehouse`
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        this.warehouses = data.list;
      }).catch((e) => {
        console.log(e);
      });
    },
    watchMsg() {
      if (Object.keys(this.$props.user_rows).length) {
        const eventSource = new EventSource(`/api/message?userId=${this.$props.user_rows.id}`);
        eventSource.onopen = function () {
          console.log('連接成功');
        };
        eventSource.onmessage = function (e) {
          const data = JSON.parse(e.data);
          console.log('message');
          console.log(data);
        };
        //eventSource.addEventListener('error', function (e) {
        //  console.log(`Error:`, e);
        //  eventSource.close();
        //}, false);
        document.addEventListener('unload', function () {
          eventSource.close();
        }, false);
      }
    },
    checkUserRows() {
      if (Object.keys(this.$props.user_rows).length == 0) {
        this.$router.push('/login');
      } else {
        if (this.$props.user_rows.first_name == null || 
          this.$props.user_rows.last_name == null || 
          this.$props.user_rows.birthday == null || 
          (this.$props.user_rows.stats && this.$props.user_rows.stats.length == 0) ||
          this.$props.user_rows.tel == null
        ) {
          this.user_dialog.name = this.$props.user_rows.name;
          this.user_dialog.user = this.$props.user_rows;
          this.user_dialog.isShow = true;
        } else if (this.$props.user_rows.dr_books && 
          Array.isArray(this.$props.user_rows.dr_books) && 
          this.$props.user_rows.dr_books.length === 0
        ) {
          //this.ez_dialog.isShow = true;
        }
        let redirect_url = sessionStorage.getItem('redirect_url');
        sessionStorage.removeItem('redirect_url');
        if (redirect_url) {
          this.$router.push(redirect_url);
        } else {
          this.$router.push('/main');
        }
      }
    },
    message() {
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "115625591474566");
      chatbox.setAttribute("attribution", "biz_inbox");

      window.fbAsyncInit = function() {
        window.FB.init({
          xfbml            : true,
          version          : 'v17.0'
        });
      };
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    start() {
      //this.$refs.main.style.height = `calc(100vh - 70px)`;
      //this.$refs.main.style.overflow = `hidden`;
    },
    end() {
      //this.$refs.main.style.height = ``;
      //this.$refs.main.style.overflow = ``;
    },
    changeUser(val) {
      this.$emit('updateStatus', val);
    }

  }
}
</script>
<style>
footer {
  display: none;
}
#fb-root {
  display: none;
}
@media (max-width: 500px) and (orientation: portrait) {
  footer {
    display: block;
    width: 100vw;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  footer {
    display: block;
    width: 100vw;
    height: 60px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
