<template>
  <div class="action-log">
    <div class="action-info">
      <p>運單單號: {{order_id}}</p>
      <p>
      {{ title }}
      <a v-if="total_logis > 1" @click="next()">切換包裹</a>
      </p>
      <template v-if="actions.length > 0">
        <dl>
          <template v-for="act in actions.slice().reverse()" :key="act.disp_date">
            <dt>{{ act.disp_date }}</dt>
            <dd v-if="typeof action_map[act.action_id] != 'undefined' && act.action_id != '50'">
              {{ action_map[act.action_id] }}
              <template v-if="typeof act['local_logis_num'] != 'undefined'">
                （新竹物流宅配單號: {{ act.local_logis_num }}）
              </template>
            </dd>
            <dd v-else v-html="act.action_name"></dd>
          </template>
        </dl>
      </template>
      <template v-else>
        <p>⚠️待付款後轉出庫狀態約一個工作天可查詢物流狀態</p>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ACTION_ID from '@/option/action_id.js'

export default {
  name: 'ActionLog',
  props: ['user_rows'],
  data() {
    return {
      all_data: {},
      actions: [],
      action_map: ACTION_ID,
      title: '分提單號碼',
      keys: [],
      order_id: null,
      total_logis: 0,
      logis_num: null,
      a_index: 0
    }
  },
  components: {
  },
  mounted() {
    this.order_id = this.$route.params.order_id;
    this.getOrder();
  },
  methods: {
    next() {
      if (this.a_index < this.keys.length - 1) {
        this.a_index++;
      } else {
        this.a_index = 0;
      }
      this.logis_num = this.keys[this.a_index];
      this.title = `分提單號碼: ${this.logis_num}`;
      this.actions = this.all_data[this.logis_num];
    },
    isFuture(check) {
      const reg = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) ([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      const Y = check.replace(reg, '$1');
      const n = parseInt(check.replace(reg, '$2').replace(/^0/, ''), 10) - 1;
      const d = parseInt(check.replace(reg, '$3').replace(/^0/, ''), 10);
      const h = parseInt(check.replace(reg, '$4').replace(/^0/, ''), 10);
      const i = parseInt(check.replace(reg, '$5').replace(/^0/, ''), 10);
      const s = parseInt(check.replace(reg, '$6').replace(/^0/, ''), 10);
      const date_obj = new Date(Y, n, d, h, i, s);
      const now = new Date();
      return (date_obj.getTime() > now.getTime());
    },
    getOrder() {
      let config = {
        method: 'get',
        url: `/api/action_order/${this.order_id}`
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) {
          if (data.ret.action && Object.keys(data.ret.action).length) {
            this.all_data = data.ret.action;
            this.keys = Object.keys(this.all_data);
            this.total_logis = this.keys.length;
            if (this.total_logis > 0) {
              this.logis_num = this.keys[0];
              this.actions = this.all_data[this.logis_num];
              this.title = `分提單號碼: ${this.logis_num}`;
              //filter掉未來的時間
              this.actions = this.actions.filter((arr) => {
                return !this.isFuture(arr.disp_date);
              });
            }
          }
        }
      }).catch((e) => {
        console.log(e);
      })
    }
  }
}
</script>
<style lang="scss" scope>
div.action-log {
  display: block;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  > div.action-info {
    > p {
      height: 32px;
      line-height: 32px;
      font-size: 11pt;
      width: 100%;
      text-align: left;
      padding: 0 0 0 12px;
      > a {
        cursor: pointer;
        display: inline-block;
        padding: 0 12px;
        height: 32px;
        line-height: 32px;
        background-color: #fff;
        color: #000;
        white-space: nowrap;
        font-size: 9pt;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        border: none;
        border-radius: 8px;

      }
    }
    > dl {
      width: 100%;
      text-align: left;
      > dt {
        height: 32px;
        line-height: 32px;
        font-size: 11pt;
        font-weight: bold;
        color: #e67e22;
        padding: 0 0 0 12px;
      }
      > dd {
        min-height: 32px;
        line-height: 32px;
        font-size: 11pt;
      }

    }
  }
}
</style>
