<template>
  <div class="logo">
    <a ref="go" href="https://planets.com.tw" target="_blank"></a>
  </div>
  <ul class="menu">
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('main'),main:1}" title="主頁" to="/main">主頁</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('jp_order'),'jp-order':1}" title="代購服務" to="/purchasing_agent">代購服務</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('order'),order:1}" title="訂單/追蹤" to="/order">訂單/追蹤</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('address_books'),'address-books':1}" title="收件人/申報人" to="/address_books">收件人/申報人</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('package'),package:1}" title="包裹管理" to="/package">包裹管理</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('forecast'),forecast:1}" title="預報包裹" to="/forecast">預報包裹</router-link></li>
    <li><router-link @click="savePath($event)" :class="{now:getNowPos('apply_service'),'apply-service':1}" title="服務申請" to="/apply_service">服務申請</router-link></li>
  </ul>
  <a href="https://planets.com.tw/process/" target="_blank">集運流程</a>
  <a href="https://planets.com.tw/buying/" target="_blank">代購流程</a>
  <br />
  <a href="https://planets.com.tw/qa/" target="_blank">常見問題</a>
  <a href="https://planets.com.tw/restrictions/" target="_blank">運送限制</a>
</template>

<script>
export default {
  name: 'aside-menu',
  props: ['uid'],
  mounted() {
  },
  methods: {
    getNowPos(path) {
      let regs = {
        'main': /main/,
        'jp_order': /(purchasing_agent|jp_order|jp_order\/.+|kr_order|kr_order\/.+)$/,
        'kr_order': /(kr_order|kr_order\/.+)$/,
        'order': /(\/order|\/order\/\d+)$/,
        'address_books': /address_books/,
        'package': /(package|\/order\/create|to_forecast)/,
        'forecast': /\bforecast$/,
        'apply_service': /\bapply_service/
      };
      let url = this.$route.path;
      return regs[path].test(url);
    },
    testJp() {
    },
    savePath(e) {
      let target = e.target;
      sessionStorage.setItem('redirect_url', target.getAttribute('href'));
    }

  }
}
</script>

<style lang="scss" scope>
div.logo {
  width: 100%;
  min-width: 288px;
  padding: 36px 0 2px 0px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  background: transparent url("@/assets/img/logo.png") no-repeat left 24px;
  background-size: 64px 64px;
  > a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 64px;
  }
}
ul.menu {
  min-width: 288px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  > li {
    width: 100%;
    height: 68px;
    > a {
      height: 68px;
      line-height: 68px;
      display: block;
      padding: 0 20px 0 70px;
      text-decoration: none;
      border-radius: 8px;
      color: #fff;
      font-weight: bold;
      font-size: 14pt;
      text-align: left;
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
      opacity: 0.3;
      &.main {
        background: transparent url("@/assets/img/home_white.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.jp-order {
        background: transparent url("@/assets/img/jp_order.png") no-repeat -8px 4px;
        background-size: 80px 60px;
      }
      &.kr-order {
        background: transparent url("@/assets/img/jp_order.png") no-repeat -8px 4px;
        background-size: 80px 60px;
      }
      &.order {
        background: transparent url("@/assets/img/order_white.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.address-books {
        background: transparent url("@/assets/img/books_white.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.package {
        background: transparent url("@/assets/img/package_white.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.forecast {
        background: transparent url("@/assets/img/forecast_white.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.apply-service {
        background: transparent url("@/assets/img/apply_service.png") no-repeat 16px 18px;
        background-size: 32px 32px;
      }
      &.now {
        opacity: 1;
      }
    }
  }
  & + a, 
  & + a  + a,
  & + a + a ~ a {
    display: inline-block;
    margin: 5px;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    text-decoration: none;
    color: #000;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba( 255, 255, 255, 0.5);
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  ul.menu {
    > li {
      height: 48px;
      > a {
        height: 48px;
        &.main {
          background: transparent url("@/assets/img/home_white.png") no-repeat 16px 9px;
          background-size: 32px 32px;
        }
        &.jp-order {
          background: transparent url("@/assets/img/jp_order.png") no-repeat -8px 4px;
          background-size: 80px 60px;
        }
        &.kr-order {
          background: transparent url("@/assets/img/jp_order.png") no-repeat -8px 4px;
          background-size: 80px 60px;
        }
        &.order {
          background: transparent url("@/assets/img/order_white.png") no-repeat 16px 9px;
          background-size: 32px 32px;
        }
        &.address-books {
          background: transparent url("@/assets/img/books_white.png") no-repeat 16px 9px;
          background-size: 32px 32px;
        }
        &.package {
          background: transparent url("@/assets/img/package_white.png") no-repeat 16px 9px;
          background-size: 32px 32px;
        }
        &.forecast {
          background: transparent url("@/assets/img/forecast_white.png") no-repeat 16px 9px;
          background-size: 32px 32px;
        }
      }
    }
  }
}
</style>

