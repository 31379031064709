<template>
  <teleport to="body"> 
   <div class="ezway_mask" :class="{show:isShow}" @click="outClick($event)">
      <div class="ezway_box_outer">
        <form class="ezway_box" method="post" action="/api/dr_books" @change="toCheck()" ref="box_form">
          <div class="title">
            {{ title }} 
            <div class="close" v-if="!force || step === 3" @click="no()"></div>
          </div>
          <div class="content">
            <p v-if="fail && step < 3">{{ fail }}</p>
            <div class="warn-box" v-if="step === 0">
              <p v-if="force">請先完成EZWay 申報人認證</p>
              <p v-else>台灣進口申報類型</p>
            </div>
            <div class="dr-data" v-show="step === 1">
              <div>
                <label v-if="type === 'person'">申報人姓名</label>
                <label v-else>公司名稱</label>
                <input type="text" name="dr_recipient" required/>
              </div>
              <div>
                <label v-if="type === 'person'">申報人身分證字號</label>
                <label v-else>統一編號</label>
                <input type="text" name="dr_id_issure" required />
              </div>
              <div>
                <label v-if="type === 'person'">申報人電話</label>
                <label v-else>電話</label>
                <input type="tel" name="dr_tel" required />
              </div>
              <small v-if="type === 'company'">需提供紙本個案/長期委任書。</small>
            </div>
            <div class="dr-data" v-show="step === 2">
              <div v-if="edata.pic1 == null">
                <label>首頁截圖<a target="_blank" href="/ezway/ezway02.webp">圖例</a></label>
                <input type="file" name="pic[]" accept="image/*" required />
              </div>
              <div v-else>
                <label>首頁截圖<a target="_blank" :href="`/api/img/${edata.pic1}`">檢視</a></label>
                <input type="file" name="pic1" accept="image/*" />
              </div>
              <div v-if="edata.pic2 == null">
                <label>個人檔案截圖<a target="_blank" href="/ezway/ezway01.webp">圖例</a></label>
                <input type="file" name="pic[]" accept="image/*" required />
              </div>
              <div v-else>
                <label>個人檔案截圖<a target="_blank" :href="`/api/img/${edata.pic2}`">檢視</a></label>
                <input type="file" name="pic2" accept="image/*" />
              </div>
            </div>
            <div class="finish-box" v-show="step === 3">
              <p :data-small="smallMsg()">已收到您的EZWay 申請！</p>
            </div>
          </div>
          <a v-if="step === 0" @click="go('person')">登記個人戶</a>
          <a v-if="step === 0" @click="go('company')">登記公司戶</a>
          <a v-if="step === 2" @click="bef()">上一步</a>
          <a v-if="step === 1 && edata.dr_recipient == null" @click="bef()">上一步</a>
          <a :class="{'dis': !is_ok}" v-if="step === 1 && type === 'person'" @click="savePerson()">送出</a>
          <a :class="{'dis': !is_ok}" v-if="step === 1 && type === 'company'" @click="toSave()">送出</a>
          <a :class="{'dis': !is_ok}" v-if="step === 2" @click="toSave()">送出</a>
          <fieldset v-if="step === 1 && type === 'person'">
            <legend>重要提醒</legend>
            <p>請務必確認您所輸入資料和您註冊&amp;驗證 EZWay 資料相符，可參考以下<a target="_blank" href="/img/example.png">圖例</a>。</p>
            <p>如申報不實可能需於進口時提供紙本文件、 產生罰金或遭退運（相關費用需自行負擔。）</p>
          </fieldset>
        </form>
      </div>
    </div>
  </teleport> 
</template>

<script>
import axios from 'axios';
export default {
  name: 'ezway-dialog',
  emits: ['toInput'],
  props: {
    isShow: {
      default: false
    },
    force: {
      default: false
    },
    fail: {
      default: null
    },
    edata: {
      default: {
        id: null,
        dr_recipient: null,
        dr_id_issure: null,
        dr_tel: null,
        pic1: null,
        pic2: null
      }
    }
  },
  data() {
    return {
      title: '',
      is_ok: true,
      step: 0,
      type: null,
      lock: false
    };
  },
  watch: {
    isShow: function (newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        if (this.$props.edata.dr_recipient == null) {
          this.step = 0;
          this.is_ok = false;
          this.$refs.box_form.reset();
        } else {
          this.step = 1;
          if (this.$props.edata.pic1 && this.$props.edata.pic2) {
            this.type = 'person';
          } else {
            this.type = 'company';
          }
          this.$refs.box_form.reset();
          this.$refs.box_form.dr_recipient.value = this.$props.edata.dr_recipient;
          this.$refs.box_form.dr_id_issure.value = this.$props.edata.dr_id_issure;
          this.$refs.box_form.dr_tel.value = this.$props.edata.dr_tel;
          this.$refs.box_form.dispatchEvent(new Event('change'));
        }
        //this.$refs.name.value = 'CD (專輯 / 光碟)';
        (this.step === 0) && (this.is_ok = true);
        (this.step === 0) && (this.title = '');
        (this.step === 1) && (this.title = 'EZWay 申報人認證');
      }
    }
  },
  mounted() {
  },
  methods: {
    outClick(e) {
      const target = e.target;
      if (target.tagName.toUpperCase() == 'DIV' && target.classList.contains('ezway_box_outer') && (!this.force || this.step == 3)) {
        this.no();
      }
    },
    toCheck() {
      const fdata = new FormData(this.$refs.box_form);
      const fields = ['dr_recipient', 'dr_id_issure', 'dr_tel'];
      if (this.step === 0 || this.step === 3) {
        this.is_ok = true;
      } else {
        this.is_ok = true;
        if (this.step === 1) {
          fields.forEach((f) => {
            (fdata.get(f) === '') && (this.is_ok = false);
          });
        }
        if (this.step === 2) {
          if (this.$props.edata.pic1) {
            this.is_ok = true;
          } else {
            (fdata.getAll('pic[]')[0].name === '') && (this.is_ok = false);
            (fdata.getAll('pic[]')[1].name === '') && (this.is_ok = false);
          }
        }
      }
    },
    yes() {
    },
    no() {
      this.$emit('toInput', '0');
    },
    bef() {
      this.is_ok = true;
      this.step -= 1;
      (this.step === 0) && (this.title = '');
    },
    smallMsg() {
      if (this.type === 'person') {
        return `登記成功！ 如需新增更多申報人資料，可於收件/申報人新增`;
      } else {
        return `需提供紙本個案/長期委任書`;
      }
    },
    go(type) {
      (type === 'person') && (this.title = 'EZWay 申報人認證');
      (type === 'company') && (this.title = 'EZWay 公司認證');
      this.type = type;
      this.step = 1;
      this.toCheck();
    },
    next() {
      if (this.is_ok) {
        this.step++;
        if (this.step > 0 && this.step < 3) {
          this.title = 'EZWay 申報人認證';
        } else {
          this.title = '';
        }
        this.$refs.box_form.dispatchEvent(new Event('change'));
      }
    },
    overDate() {
    },
    savePerson() {
      if (this.lock === false) {
        this.lock = true;
        const token = localStorage.getItem('remember_token');
        const post_data = new FormData(this.$refs.box_form);
        post_data.append('person', '1');
        let action = `/api/member/dr_books`;
        if (this.edata.id) {
          post_data.append('_method', 'PUT');
          action = `/api/member/dr_books/${this.edata.id}`;
        }
        const config = {
          method: 'post',
          url: action,
          data: post_data,
          headers: {
            'Authentication': token
          }
        };
        axios(config).then((json) => {
          return json.data;
        }).then((data) => {
          this.lock = false;
          if (data.err) {
            alert(data.err);
          } else {
            this.$emit('toInput', data.ret);
            this.step = 3;
          }
        }).catch((e) => {
          console.log(e);
          this.lock = false;
        });
      }
    },
    toSave() {
      if (this.lock === false) {
        this.lock = true;
        const token = localStorage.getItem('remember_token');
        const post_data = new FormData(this.$refs.box_form);
        let action = `/api/member/dr_books`;
        if (this.edata.id) {
          post_data.append('_method', 'PUT');
          action = `/api/member/dr_books/${this.edata.id}`;
        }
        if (this.type === 'company') {
          const reg = /[a-zA-Z]+/;
          //公司統編有英文
          if (reg.test(post_data.get('dr_id_issure'))) {
            alert('統編包含英文！請返回上一步選擇【個人戶】驗證');
            return false;
          }
        }
        const config = {
          method: 'post',
          url: action,
          data: post_data,
          headers: {
            'Authentication': token
          }
        };
        axios(config).then((json) => {
          return json.data;
        }).then((data) => {
          this.lock = false;
          if (data.err) {
            alert(data.err);
          } else {
            this.$emit('toInput', data.ret);
            this.step = 3;
          }
        }).catch((e) => {
          console.log(e);
          this.lock = false;
        });
      }
    }
  }
}
</script>

<style scope lang="scss">
.ezway_mask { 
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw; 
    height: 100vh;
    z-index: -1;
    z-index: 10102;
    visibility: hidden;
    backdrop-filter: blur(10px);
}
.ezway_mask.show { 
    z-index: 10102;
    visibility: visible;
}
.ezway_box_outer {
    position: fixed;
    z-index: 10103;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
}
.ezway_box_outer::before {
    content : '';
    display: block;
    width: 100%;
    height: 25%;
}
.ezway_mask > .ezway_box_outer > .ezway_box { 
    transition: all 0.2s ease-in;
    transform: translateY(100%) translateZ(0);
    opacity: 0;
}
.ezway_mask.show > .ezway_box_outer > .ezway_box { 
    transform: translateY(0) translateZ(0);
    opacity: 1;
}
.ezway_box {
  background-color: #ffffff;
  background-color: #f8f8f8;
  line-height: 0;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-size: 10pt;
  margin: 0 auto;
  width: 66vw;
  max-width: 500px;
  min-width: 320px;
  height: 50vh;
  min-height: 356px;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  > div.content {
    > p {
      color: red;
      font-weight: bold;
    }
    > div.warn-box,
    > div.finish-box {
      width: 100%;
      display: block;
      height: 256px;
      background: transparent url("@/assets/img/level1.png") no-repeat center top;
      background-size: 200px 200px;
      padding: 200px 0 0 0;
      > p {
        line-height: 32px;
        font-size: 16pt;
        margin: 0;
        text-align: center;
        font-weight: bold;
        &[data-small]::after {
          content: attr(data-small);
          display: block;
          line-height: 16px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
    > div.dr-data {
      display: block;
      margin: 0 auto;
      max-width: 500px;
      > div {
        line-height: 44px;
        font-size: 10pt;
        > label {
          display: block;
          font-weight: bold;
          width: calc(55% - 32px);
          font-size: 10pt;
          float: left;
          > a {
            display: inline-block;
            padding: 0 5px;
            margin: 0 5px;
            border-radius: 3px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            height: 32px;
            line-height: 32px;
            text-decoration: none;
            color: #111;
          }
          margin: 0;
          padding: 0 16px;
        }
        > input {
          display: inline-block;
          width: 45%;
          border-radius: 5px;
          border: none;
          box-shadow: 0 0 5px rgba(0,0,0,0.3);
          font-size: 10pt;
          float: right;
          line-height: 32px;
          margin: 2px 16px;
        }
        &::after {
          content: '';
          display: block;
          height: 0;
          clear: both;
        }
        + small {
          line-height: 45px;
          color: #aaa;
        }
      }
    }
  }
  fieldset {
    border: none;
    font-size: 11pt;
    display: block;
    width: 80%;
    margin: 15px auto;
    > legend {
      color: red;
      line-height: 32px;
    }
    > p {
      line-height: 20px;
      margin: 0;
      text-align: left;
      color: #666;
      > a {
        display: inline-block;
        padding: 0 5px;
        margin: 0 5px;
        border-radius: 3px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        height: 32px;
        line-height: 32px;
        text-decoration: none;
        color: #111;
      }
    }
  }
}
.ezway_box .title {
    width: auto; 
    display: block;
    height: 45px;
    line-height: 45px;
    background-color: #f8f8f8;
    color: #000;
    font-size: 11pt;
    font-weight: bold;
    position: relative;
}
.ezway_box .title .close{
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
    top: 16px;
    left: 20px;
    color: #000;
    text-shadow: none;
    cursor: pointer;
    background: transparent url("@/assets/img/close.png") no-repeat center center;
    background-size: 24px 24px;
}
.ezway_inner {
    margin: 0;
    padding: 0.5em; 
    background: #f8f8f8;
    line-height: 10pt;
    font-size: 10pt;
    text-align: center;
    min-height: 50px;
    overflow-x: inherit;
    overflow-y: auto;
    box-sizing: border-box;
}
.ezway_box > label {
    color: #6f6f80;
    display: block;
    height: 24px;
    line-height: 24px;
    width:100%;
    text-align: left;
    margin: 0 auto;
}
.ezway_box > input[type=date] {
    width: 80%;
    font-size: 11pt;
    margin: 12px auto;
    border-radius: 0px;
    border: 1px solid #bbbbbb;
    border: none;
    background-color: #6b6767;
    background-color: #f7f7f9;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    color: #fff;
    color: #111;
    border-radius: 15px;
    &:placeholder {
      color: #d8d6d5;
      color: #b2b8c2;
    }
}
.ezway_box > select, 
.ezway_box > input[type=number], 
.ezway_box > input[type=text] {
    height: 55px;
    line-height: 55px;
    width: 80%;
    display: block;
    border-radius: 8px;
    border: 1px solid #6f6f80;
    padding: 0 16px;
    font-size: 18px;
    margin: 0 auto 20px auto;
    border: none;
    background-color: #6b6767;
    background-color: #f7f7f9;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    color: #fff;
    color: #111;
    border-radius: 15px;
    &:placeholder {
      color: #b2b8c2;
    }
}
.ezway_box > input[type=number]::placeholder, 
.ezway_box > input[type=text]::placeholder {
  color: red;
}
.ezway_box > input[type=text] + b {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 15px 0 0 -28px;
    background-size: 24px 24px;
}
.ezway_box > input[type=text]::ms-clear {
    display: none;
}
.ezway_box > input[type=text]:valid + b {
    display: inline;
}

.ezway_box > a {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: 100%;
    width: 120px;
    height: 32px;
    margin: 12px;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    line-height: 32px;
    font-family: inherit;
    font-size: 12pt;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(0,122,255);
    color: #111;
    background-color: #f8f8f8;
    background-color: #e9be8b;
    box-sizing: border-box;
}
.ezway_box > a.YES {
}
.ezway_box > a.NO {
    font-weight: bold;
    display: none;
}
.ezway_box > a.dis {
    background-color: #9b9b9b;
    color: #fff;
}
.ezway_box::after {
    content: '';
    height: 0;
    width: 100%;
    overflow: hidden;
    clear: both;
}
@media (max-width: 500px) and (orientation: portrait) {
  .ezway_box {
    min-height: 350px;
    width: 100%;
    height: calc(75vh + 30px);
  }
  .ezway_box > select, 
  .ezway_box > input[type=number], 
  .ezway_box > input[type=text] {
    height: 32px;
    line-height: 32px;
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  .ezway_box_outer::before {
    height: 5vh;
  }
  .ezway_box {
    width: 100%;
    height: calc(95vh + 30px);
  }
  .ezway_box > input[type=number], 
  .ezway_box > input[type=text] {
    height: 32px;
    line-height: 32px;
  }
}
</style>
