<template>
  <teleport to="body"> 
    <div class="user_mask" :class="{show:isShow}">
      <div class="user_box_outer">
        <div class="user_box">
          <div class="title">
            {{ title }}
            <div class="close"></div>
          </div>
          <div class="content">
            <form action="/api/fill_info" ref="fill_form" method="post">
              <div>
                <label>姓</label>
                <div>
                  <input type="text" name="last_name" :value="last_name" />
                </div>
              </div>
              <div>
                <label>名</label>
                <div>
                  <input type="text" name="first_name" :value="first_name" />
                </div>
              </div>
              <div>
                <label>性別</label>
                <div>
                  <span><input type="radio" name="gender" :checked="user.gender == null" />不公開</span>
                  <span><input type="radio" name="gender" :checked="user.gender == 'M'" value="M" />男</span>
                  <span><input type="radio" name="gender" :checked="user.gender == 'F'" value="F" />女</span>
                </div>
              </div>
              <div>
                <label>生日</label>
                <div>
                  <input ref="birth" type="date" name="birthday" :value="user.birthday || ''" />
                </div>
              </div>
              <div>
                <label>電話</label>
                <div>
                  <input ref="tel" type="tel" name="tel" :value="user.tel || ''" />
                </div>
              </div>
              <div>
                <label>從哪裡認識我們</label>
                <div>
                  <p><input type="checkbox" name="know_us_from[]" value="Facebook" :checked="isChecked('Facebook')" />FaceBook</p>
                  <p><input type="checkbox" name="know_us_from[]" value="Instagram" :checked="isChecked('Instagram')" />Instagram</p>
                  <p><input type="checkbox" name="know_us_from[]" value="Google" :checked="isChecked('Google')" />Google搜尋</p>
                  <p><input type="checkbox" name="know_us_from[]" value="友人介紹" :checked="isChecked('友人介紹')" />友人介紹</p>
                  <p><input type="checkbox" name="know_us_from[]" value="Dcard" :checked="isChecked('Dcard')" />Dcard</p>
                  <p><input type="checkbox" name="know_us_from[]" value="部落格推薦" :checked="isChecked('部落格推薦')" />部落格推薦</p>
                  <p>
                    <input type="checkbox" ref="etc" name="know_us_from[]" :value="getEtcVal() || '其他'" :checked="isChecked(getEtcVal())" />其他
                    <input type="text" @input="chgEtc($event)" :value="getEtcVal()" />
                  </p>
                </div>
              </div>
            </form>
          </div>
          <a class="yes" @click="yes()">確定</a>
        </div>
      </div>
    </div>
  </teleport> 
</template>

<script>
import axios from 'axios';

export default {
  name: 'user-detail',
  emits: ['toFill'],
  props: {
    isShow: {default: false},
    title: {default: '補充會員資料'},
    name: {default: '星球集運'},
    user: {
      default: { 
        first_name: null,
        last_name: null,
        gender: null,
        birthday: null,
        stats: [],
      }
    },
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      gender: '',
    }
  },
  watch: {
    name: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.parseName(newVal);
      }
    }
  },
  mounted() {
  },
  methods: {
    isChecked(val) {
      if (this.$props.user.stats) {
        let check = this.$props.user.stats.filter((s) => {
          return (s.stat_val === val && s.group_id === 1);
        });
        return check.length;
      }
      return false;
    },
    getEtcVal() {
      let knows = ["Facebook", "Instagram", "Google", "友人介紹", "Dcard", "部落格推薦", "其他"]
      if (this.$props.user.stats) {
        let etc = this.$props.user.stats.filter((s) => {
          return (knows.indexOf(s.stat_val) < 0);
        });
        if (etc.length) {
          return etc[0].stat_val;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    chgEtc(e) {
      let inp = e.target;
      if (inp.value.length == 0) {
        this.$refs.etc.value = '';
      } else {
        this.$refs.etc.value = inp.value;
      }
    },
    yes() {
      if (this.$refs.birth.value.length) {
        let post_data = new FormData(this.$refs.fill_form);
        if (post_data.get('first_name') == null) {
          alert('請填寫名字');
          return false;
        }
        if (post_data.get('last_name') == null) {
          alert('請填寫姓');
          return false;
        }
        if (post_data.get('know_us_from[]') === null) {
          alert('至少勾選一項【從哪裡認識我們】');
          return false;
        }
        if (post_data.get('tel') == '') {
          alert('請填寫手機號碼');
          return false;
        }
        if (this.$refs.etc.value == '' && this.$refs.etc.checked) {
          alert('麻煩填寫從哪裡認識我們');
          return false;
        }
        let token = localStorage.getItem('remember_token');
        let config = {
          method: 'post',
          url: this.$refs.fill_form.getAttribute('action'),
          data: post_data,
          headers: {
            'Authentication': token
          }
        };
        axios(config).then((json) => {
          return json.data;
        }).then((data) => {
          if (data.result == 1) { 
            this.$emit('toFill', data.ret);
          } else if (data.err) {
            alert(data.err);
            if (data.redirect) {
              this.$router.push(data.redirect);
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      } else {
        alert('請務必填寫生日');
      }
    },
    parseName(name) {
      if (this.$props.user.first_name === null || this.$props.user.last_name === null) {
        let is_en = /^[A-Za-z\s]+$/;
        if (is_en.test(name)) {
          let en = name.replace(/\s+/g, ' ').split(' ');
          if (en.length > 1) {
            this.last_name = en[en.length - 1];
            this.first_name = en.replace(new RegExp(`\\s+${this.last_name}$`), '');
          } else {
            this.first_name = name;
          }

        } else {
          let cn = /^([\u4e00-\u9fa5])(.+)$/;
          if (cn.test(name)) {
            this.first_name = name.replace(cn, "$2");
            this.last_name = name.replace(cn, "$1");
          } else {
            this.first_name = name;
          }
        }
      } else {
        this.first_name = this.$props.user.first_name;
        this.last_name = this.$props.user.last_name;
      }

    }
  }
}
</script>

<style scope lang="scss">
.user_mask { 
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw; 
    height: 100vh;
    z-index: -1;
    z-index: 10102;
    visibility: hidden;
    backdrop-filter: blur(10px);
}
.user_mask.show { 
    z-index: 10102;
    visibility: visible;
}
.user_box_outer {
    position: fixed;
    z-index: 10103;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
}
.user_box_outer::before {
    content : '';
    display: block;
    width: 100%;
    height: 20%;
    margin-bottom: -70px;
}
.user_mask > .user_box_outer > .user_box { 
    transition: all 0.2s ease-in;
    transform: translateY(100%) translateZ(0);
    opacity: 0;
}
.user_mask.show > .user_box_outer > .user_box { 
    transform: translateY(0) translateZ(0);
    opacity: 1;
}
.user_box {
    background-color: #f8f8f8;
    line-height: 0;
    border-radius: 30px;
    font-size: 10pt;
    margin: 0 auto;
    width: 360px;
    min-width: 320px;
    overflow: hidden;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.user_box .title {
    width: auto; 
    margin: 1px;
    display: block;
    height: 45px;
    line-height: 45px;
    background-color: #f8f8f8;
    color: #000;
    font-weight: bold;
    position: relative;
    box-sizing: border-box;
    caret-color: rgb(87, 82, 80);
    color: rgb(87, 82, 80);
    font-family: Montserrat, sans-serif;
    font-size: 14pt;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: bold;
    letter-spacing: normal;
    orphans: auto;
    text-align: center;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: auto;
    word-spacing: 0px;
    -webkit-text-size-adjust: auto;
    -webkit-text-stroke-width: 0px;
    text-decoration: none;

}
.user_box .title .close{
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 10pt;
    top: 4px;
    right: 4px;
    color: #000;
    text-shadow: none;
    cursor: pointer;
    display: none;
}
.user_inner {
    margin: 0;
    padding: 0.5em; 
    background: #f8f8f8;
    line-height: 10pt;
    font-size: 10pt;
    text-align: center;
    min-height: 50px;
    overflow-x: inherit;
    overflow-y: auto;
    box-sizing: border-box;
}
.user_box > div.content {
  height: 60vh;
  overflow: auto;
  padding: 0 20px 0 20px;
  form {
    display: block;
    margin: 0;
    padding: 0;
    > div {
      line-height: 24px;
      > label {
        font-weight: bold;
        &::after {
          content: ":";
        }
      }
      > div {
        padding: 0 5px 0 5px;
        > p > input[type="checkbox"] {
          & + input[type="text"] {
            display: none;
          }
          &:checked + input[type="text"] {
            display: inline-block;
          }
        }
      }
    }
  }
  div {
    line-height: 24px;
    caret-color: rgb(87, 82, 80);
    color: rgb(87, 82, 80);
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: auto;
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: auto;
    word-spacing: 0px;
    -webkit-text-size-adjust: auto;
    -webkit-text-stroke-width: 0px;
    text-decoration: none;
    > div {
      text-align: left;
    }
  }
}
.user_box > label {
    color: #6f6f80;
    display: block;
    height: 24px;
    line-height: 24px;
    width:80%;
    text-align: left;
    margin: 0 auto;
}
.user_box > input[type=date] {
    width: 80%;
    font-size: 11pt;
    margin: 12px auto;
    border-radius: 0px;
    border: 1px solid #bbbbbb;
}
.user_box > input[type=number], 
.user_box > input[type=text] {
    height: 55px;
    line-height: 55px;
    width: 80%;
    display: block;
    border-radius: 8px;
    border: 1px solid #6f6f80;
    padding: 0 16px;
    font-size: 18px;
    margin: 0 auto 20px auto;
}
.user_box > input[type=number]::placeholder, 
.user_box > input[type=text]::placeholder {
  color: red;
}
.user_box > input[type=text] + b {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 15px 0 0 -28px;
    background-size: 24px 24px;
}
.user_box > input[type=text]::ms-clear {
    display: none;
}
.user_box > input[type=text]:valid + b {
    display: inline;
}

.user_box > a {
    display: inline-block;
    text-align: center;
    width: 100%;
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    margin: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    font-family: inherit;
    font-size: 12pt;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(0,122,255);
    color: #111;
    cursor: pointer;
    background-color: #f8f8f8;
    background-color: #e9be8b;
    box-sizing: border-box;
}
.user_box::after {
    content: '';
    height: 0;
    width: 100%;
    overflow: hidden;
    clear: both;
}
@media (max-width: 500px) and (orientation: portrait) {
  .user_box_outer::before {
    height: 10vh;
    margin-bottom: 0;
  }
  .user_box {
    width: 100%;
    > div.content {
      height: calc(90vh - 165px);
      & + a {
        margin: 0 auto 150px auto;
      }
    }
  }
}
@media (max-width: 900px) and (orientation: landscape) {
}
</style>
