const ACTION_ID = {
  '201': '貨件正在前往機場',
  '202': '貨件已到達海外機場',
  '205': '出口清關完成，等待國際航班分發',
  '204': '等待臺灣海關清關中',
  '15': '跨境作業中',
  '16': '進口清關完成',
  '50': '落地派送中',
  '99': '簽收完成',
  '402': '貨件正在前往機場',
  '403': '貨件已到達海外機場',
  '404': '出口清關完成，等待航班起飛',
};
export default ACTION_ID;
