<template>
  <div id="page">
    <router-view :user_rows="user_rows" @updateStatus="updateStatus"></router-view>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user_rows: {},
    }
  },
  async mounted() {
    this.getUserRows();
  },
  methods: {
    updateStatus(val) {
      this.user_rows = val;
    },
    getUserRows() {
      let post_data = new FormData();
      let token = localStorage.getItem('remember_token');
      post_data.append('token', token);
      let config = {
        method: 'post',
        url: '/api/remember',
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        this.user_rows = data;
      }).catch((e) => {
        console.log(e);
      });
    },
  }
}
</script>

<style lang="scss" src="@/assets/scss/package.scss">
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
html, body {
  margin: 0;
  padding: 0;
  overflow: visible;
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box; 
}
#page {
  background-color: #f5f6f7;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  &.more {
    > header > nav#header {
      width: calc(100% - 116px);
    }
    > aside {
      width: 81px;
    }
    > main {
      padding: 160px 40px 20px 96px;
    }
  }
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  text-align: left;
}
</style>
